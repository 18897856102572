/* global moment */

angular.module('LeasePilot').controller('DashboardController', [
  '$scope',
  '$rootScope',
  '$mdDialog',
  'DocumentService',
  'ShareService',
  'FormService',
  'CompanyService',
  'BuildingService',
  'DownloadService',
  '$http',
  '$timeout',
  '$window',
  function(
    $scope,
    $rootScope,
    $mdDialog,
    DocumentService,
    ShareService,
    FormService,
    CompanyService,
    BuildingService,
    DownloadService,
    $http,
    $timeout,
    $window,
  ) {
    $scope.isLoaded = false;

    $scope.init = async function() {
      $rootScope.$on('loadUser', () => {
        $scope.company = window.user.company;
      });

      $rootScope.forms = await FormService.query({
        testing: window._testing_assets_enabled,
      });
      $rootScope.buildings = await BuildingService.query({
        dashboard: true,
      });
      
      $scope.isLoaded = true;
      $scope.safeApply();

      window.track.event(new LoadDashboardEvent({
        context: $rootScope.getContext(),
      }));
    };

    $scope.showDialog = function(event, isConformedDeal, companyId) {
      companyId = window.user.company.id;

      var template =
        "<md-dialog><new-document-modal company-id='" +
        companyId +
        "' is-conformed-deal='" +
        isConformedDeal +
        "'></new-document-modal></md-dialog>";

      openDialog(template);
    };

    $scope.openDuplicateDialog = function(companyId) {
      companyId = companyId || '';
      var template =
        '<md-dialog>' +
        "<duplicate-company-modal company-id='" +
        companyId +
        "'>" +
        '</duplicate-company-modal></md-dialog>';

      openDialog(template);
    };

    $scope.newCompanyDialog = function() {
      var template =
        '<md-dialog>' +
        '<new-company-modal>' +
        '</new-company-modal></md-dialog>';

      openDialog(template);
    };

    var openDialog = function(template) {
      $mdDialog.show({
        controller:
          ('DialogController', ['$scope', '$mdDialog', DialogController]),
        template: template,
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        fullscreen: false,
      });

      function DialogController($scope, $mdDialog) {
        $scope.cancel = function() {
          $mdDialog.cancel();
        };
      }
    };

    $scope.confirmDeleteCompany = function(companyId, companyName) {
      var template =
        '<md-dialog>' +
        "<delete-company-modal company-id='" +
        companyId +
        "' company-name='" +
        companyName +
        "'>" +
        '</delete-company-modal></md-dialog>';

      openDialog(template);
    };

    $scope.showConfirmDeleteDocument = function(id, type) {
      var buttons = {
        ok: 'OK',
        cancel: 'Cancel',
      };
      $scope.currentLeaseId = id;
      $scope.currentLeaseType = type;
      $scope.openConfirmModal(
        `You are about to permanently delete a document from LeasePilot.
      Press OK to confirm, or Cancel to keep it in the Archive.`,
        () =>
          $scope.deleteDocument($scope.currentLeaseId, $scope.currentLeaseType),
        null,
        buttons,
      );
    };

    window.showConfirmDeleteDocument = $scope.showConfirmDeleteDocument;

    $scope.openConfirmModal = function(
      msg,
      okCallback,
      cancelCallback,
      buttons,
      redirectUrl,
    ) {
      var confirm = $mdDialog.confirm({
        skipHide: true,
        template:
          '<div class="confirm-modal md-dialog-content">' +
          '<p class="title" >' +
          msg +
          '</p>' +
          '<div class="modal-actions">' +
          '<button ng-if=' +
          _.has(buttons, 'ok') +
          ' class="btn blue-outline" ng-click="dialog.hide()">' +
          buttons.ok +
          '</button>' +
          '<button ng-if=' +
          _.has(buttons, 'cancel') +
          ' class="btn blue active" ng-click="dialog.abort()">' +
          buttons.cancel +
          '</button>' +
          '</div>' +
          '</div>',
        onRemoving: function() {
          if (redirectUrl) {
            window.location.href = redirectUrl;
          }
        },
      });

      $mdDialog.show(confirm).then(
        function confirmPublish() {
          if (okCallback) okCallback();
        },
        function preventPublish() {
          if (cancelCallback) cancelCallback();
        },
      );
    };

    $scope.showExportErrorsDialog = function(
      event,
      leaseId,
      targetStatus,
      errors,
    ) {
      $mdDialog.show({
        template:
          '<md-dialog><show-export-errors-modal ' +
          "lease-id='" +
          leaseId +
          "' " +
          "target-status='" +
          targetStatus +
          "' " +
          "errors='" +
          JSON.stringify(errors) +
          "'>" +
          '</show-export-errors-modal></md-dialog>',
        parent: angular.element(document.body),
        targetEvent: event,
        clickOutsideToClose: true,
        fullscreen: false,
      });
    };

    window.showExportErrorsDialog = $scope.showExportErrorsDialog;

    $scope.displayLocalDate = function(datetimeString) {
      return moment(datetimeString)
        .add(moment().utcOffset(), 'm')
        .format('MM/DD/YYYY');
    };

    $scope.exportDocuments = function() {
      const documents = window.__LEASEPILOT_DOCUMENTS_TO_EXPORT;
      if (!documents || documents.length == 0) {
        return;
      }
      let data = [];

      documents.forEach(doc => {
        const record = {
          id: doc.id,
          tenant: doc.tenant,
          asset: doc.asset,
          document_type: doc.document_type,
          created_by: doc.created_by,
          created_at: doc.created_at? $scope.displayLocalDate(doc.created_at) : '',
          modified_at: doc.modified_at? $scope.displayLocalDate(doc.modified_at) : '',
          version: doc.version,
          status: doc.status.current ? doc.status.current.description : '',
          square_footage: doc.meta_data.square_footage,
          initial_base_rent: doc.meta_data.initial_base_rent,
          modification_type: doc.meta_data.modification_type,
          commencement_date: doc.meta_data.commencement_date? $scope.displayLocalDate(doc.meta_data.commencement_date) : '',
          current_draft_start: doc.meta_data.current_draft_start? $scope.displayLocalDate(doc.meta_data.current_draft_start) : '',
          current_draft_end: doc.meta_data.current_draft_end? $scope.displayLocalDate(doc.meta_data.current_draft_end) : '',
          average_draft: doc.meta_data.average_draft,
        };
        for (let i = 0; i < doc.meta_data.status_history.length; i++) {
          record[`status_history_${i+1}_name`] = doc.meta_data.status_history[i].name;
          record[`status_history_${i+1}_date`] = doc.meta_data.status_history[i].date;
        }

        data.push(record);
      });

      try {
        const csv = json2csv.parse(data);
        const type = 'text/csv';
        const date = moment().format('YYYY-MM-DD HH:mm:ss');
        const filename = `leasepilot-dashboard-${date}.csv`;

        DownloadService.download(
          csv,
          type,
          filename,
        );

        window.track.event(new ExportDocumentsEvent({
          context: 'dashboard',
        }));
      } catch (err) {
        console.error(err);
      }
    }

    $scope.deleteDocument = function(id, type) {
      DocumentService.get({
        id: id,
        type: type,
      }).then(
        function(doc) {
          if (doc != null) {
            doc.delete().then(function() {
              window.location.reload();
            });
          }
        },
        function(error) {
          console.log(error);
        },
      );
    };

    $scope.selectAccount = function(accountId) {
      document.cookie = `company_id=${accountId}; path=/;`;
      window.location.href = '/';
    };
  },
]);
